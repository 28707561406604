/*  home-double
    ========================================================================== */
    &.home .home-double {
        margin-bottom: 8vh;
        padding-bottom: 0;
        img {
            max-width: 100%;
            height: auto;
            margin: 4vh 0 -8vh 0;
        }
    }
/* ==========================================================================
   body
   ========================================================================== */
   $body-padding: 143px 0 0 0;
   $body-sticky-padding: 143px 0 0 0;

 
/* ==========================================================================
   containers
   ========================================================================== */
   $container-padding: 0 15px;
   $container-padding_sm: 0 15px;

   $container-fluid-max_width: 1320px;


/* ==========================================================================
   content
   ========================================================================== */
   
   $content-padding: 6vh 0;

/*  content > h1
    ========================================================================== */
    $content-h1-font_size: 2.25rem; /* 36px */
    $content-h1-font_size_sm: 3rem; /* 48px */
    $content-h1-color: $headings-color;

/*  content > h2
    ========================================================================== */
    $content-h2-font_size: 1.875rem; /* 30px */
    $content-h2-font_size_sm: 1.875rem; /* 30px */
    $content-h2-color: $headings-color;

/*  content > h3
    ========================================================================== */
    $content-h3-font_size: 1.5rem; /* 24px */
    $content-h3-font_size_sm: 1.5rem; /* 24px */
    $content-h3-color: $headings-color;

/*  content > h4
    ========================================================================== */
    $content-h4-font_size: 1.25rem; /* 20px */
    $content-h4-font_size_sm: 1.25rem; /* 20px */
    $content-h4-color: $headings-color;

/*  content > h5
    ========================================================================== */
    $content-h5-margin: 0;
    $content-h5-font_family: $font-family-open-sans;
    $content-h5-font_size: 1.125rem; /* 18px */
    $content-h5-font_size_sm: 1.125rem; /* 18px */
    $content-h5-font_weight: 300;
    $content-h5-color: $orange;

/*  content > h6
    ========================================================================== */
    $content-h6-font_size: 1rem; /* 16px */
    $content-h6-font_size_sm: 1rem; /* 16px */
    $content-h6-color: $headings-color;

/*  content > p
    ========================================================================== */
    $content-p-font_size: 0.875rem; /* 14px */

/*  content > ul & ol
    ========================================================================== */
    $content-list-item-font_size: 14px;

    /* content > ul.custom-list > icon */
    $content-custom-list-item-icon-color: $orange;
    $content-custom-list-item-icon-font_size: 16px;
    $content-custom-list-item-icon-font_weight: $font-weight-bold;

    /* content > ol.custom-list > icon */
    $content-ol-custom-list-item-icon: '\f00c';
    $content-ol-custom-list-item-icon-font_size: 16px;
    $content-ol-custom-list-item-icon-font_weight: $font-weight-bold;


/* ==========================================================================
    card
    ========================================================================== */
    $card-background: transparent;
    $card-border: none;
    $card-base-border-radius: 0;

/*  card > card-body
    ========================================================================== */
    $card-body-padding: 15px 0 0 0;

/*  card > card-buttons
    ========================================================================== */
    $card-buttons-margin: auto 0 0 0;

/*  card > card-caption
    ========================================================================== */
    $card-caption-flex: 0 1 auto;

    /* card > card-title */
    $card-title-font_size: 1.25rem; /* 20px */
    $card-title-font_size_sm: 1.5rem; /* 24px */
    $card-title-color: $headings-color;

    /* card > card-description */
    $card-description-max_height: auto;
    $card-description-margin: 0 0 15px 0;
    $card-description-fade-background: linear-gradient(180deg, rgba(#ffffff,0) 0%, rgba(#ffffff,1) 100%);


/*  ==========================================================================
    owl-carousel
    ========================================================================== */
    $carousel-item_padding_lg: 10vh 0;

    $carousel-title-font_size: 1.875rem;

    $carousel-subtitle-margin: 0; 
    $carousel-subtitle-font_family: $font-family-open-sans;
    $carousel-subtitle-font_size: 1.125rem;
    $carousel-subtitle-font_weight: 300;
    $carousel-subtitle-color: $orange;

    $carousel-description-margin: 0 0 30px 0;
    $carousel-description-font_size: 0.875rem;

/*  owl-carousel > eyecatcher
    ========================================================================== */
    $carousel-min_height: 50vh;
    $carousel-min_height_lg: 50vh;


/*  ==========================================================================
    footer
    ========================================================================== */
    $footer-top-padding: 0;
    $footer-top-background: none;
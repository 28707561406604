.eyecatcher,
.eyecatcher.large {
	margin: 0 15px;
	.owl-carousel,
	.owl-carousel.large {
		.item {
			padding: 0;
			.owl-container { height: 100%;}
			.owl-caption { 
				z-index: 1;
				position: relative;
				height: 100%;
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;
                color: $white;
				text-align: center;
				.owl-subtitle {
					order: 0;
					color: $white;
					font-size: 20px;
                    font-weight: 300;
					text-shadow: 3px 3px 6px rgba($color: $black, $alpha: 0.15);
					@include media-breakpoint-up(xl) {
                        font-size: 36px;
					}
					animation: 1s .25s slideInLeft both;
				}
				.owl-title {
					order: 1;
					margin-bottom: 0;
					color: $white;
					font-size: 36px;
					font-weight: 700;
					text-shadow: 3px 3px 6px rgba($color: $black, $alpha: 0.15);
					@include media-breakpoint-up(xl) {
                        font-size: 48px;
					}
					animation: 1s .25s slideInRight both;
				}
				.owl-btn {
					position: absolute;
					left: auto;
					right: auto;
					bottom: -35px;
					padding: 0 2rem;
					line-height: 68px;
					border: none;
					box-shadow: none !important;
					&:hover {
						background-color: $blue;
						border-color: $blue;
						color: $white;
					}
					&::after {
						content: "\f0a9";
						font-family: "Font Awesome 5 Pro";
						margin-left: 10px;
						color: $white;
						font-size: 24px;
						font-weight: 900;
					}
				}
			}
		}
		.owl-dots,
		.owl-nav {
			@extend .d-none;
		}
	}
	.owl-carousel.large {
		.item {
			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: rgba($color: $black, $alpha: 0.3);
			}
		}
	}
}
&.home .eyecatcher .owl-stage-outer { padding-bottom: 35px;}
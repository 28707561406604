/*  bootstrap > colors
    ========================================================================== */
    $gray:          #464541;
    $orange:        #fb9f30;
    $blue:          #1a204f;
    $beige:         #f0ede9;

/*  bootstrap > theme-colors
    ========================================================================== */
    $primary:       $blue;
    $secondary:     $orange;

    $dark:          $beige;

/*  bootstrap > body
    ========================================================================== */
    $body-color:    $gray;
    $link-color:    $primary;

/*  bootstrap > font
    ========================================================================== */
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=Tinos:wght@400;700&display=swap');
    
    $font-family-tinos: 'Tinos', serif;
    $font-family-open-sans: 'Open Sans', sans-serif;
    $font-family-base: $font-family-open-sans;

/*  bootstrap > typography
    ========================================================================== */    
    $headings-font-family:  $font-family-tinos;
    $headings-color:        $primary;

/*  misc
    ========================================================================== */   

    /* transition */
    a,
    .btn,
    .owl-btn,
    .card-btn { transition: 0.5s !important;}
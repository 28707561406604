/*  footer
    ========================================================================== */
    .footer {

        /* footer-top */
        .footer-top {
            padding: 5vh 0;
            background-color: $blue;
            color: $white;
            text-align: center;
            h5 {
                margin-bottom: 0;
                color: $orange;
                font-weight: 300;
                font-size: 18px;
                font-family: $font-family-open-sans;
            }
            h2 {
                color: $white;
                font-size: 30px;
            }
            .buttons {
                margin-bottom: 0;
                justify-content: center;
                li {
                    margin: 15px 15px 0 15px;
                    a {
                        @extend .btn;
                    }
                }
            }
        }

        /* footer-bottom */
        .footer-bottom {
            padding: 4vh 0;
            background-color: $beige;
            text-align: center;
            .social {
                margin-top: 3vh;
                .list {
                    flex-wrap: wrap;
                    justify-content: center;
                    .list-item {
                        margin: 5px 2.5px 0 2.5px;
                        .link {
                            display: block;
                            width: 36px;
                            height: 36px;
                            border-radius: 100%;
                            background: $orange;
                            color: $white;
                            font-size: 18px;
                            line-height: 36px;
                            text-align: center;
                            i { line-height: 36px;}
                            &:hover {
                                background: $blue;
                                color: $white;
                            }
                        }
                    }
                }
            }
        }

       /* footer-socket */
       .footer-socket {
            padding: 25px 0 50px 0;
            background-color: $white;
            font-size: 12px;
            text-align: center;
            .list {
                justify-content: center;
                .list-item {
                    margin-right: 20px;
                    .link {
                        color: $black;
                    }
                }
            }
        }
    }

/* hide footer-top when form is sent */
&.form-sent {
	.footer-top {
		display: none;
	}
}

@import './0_config/vendor-variables';
@import '~framework/assets/scss/Dev/0_config/vendor-variables';
@import '~framework/assets/scss/Dev/setup';
@import './0_config/website-variables';
@import '~framework/assets/scss/Dev/0_config/website-variables';

@import '~framework/assets/scss/Dev/1_vendor/bootstrap';

body.default {
	@import '~framework/assets/scss/Dev/base';

	@import './2_layout/content';
	@import './2_layout/eyecatcher';
	@import './2_layout/footer';
	@import './2_layout/header';

	@import './3_components/buttons';
	@import './3_components/cards';

	@import './4_page_block/collections';

	@import './5_utilities/animate';

	@import './6_theme/page.home';
	@import './6_theme/page.landing';
}
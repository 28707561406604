/*  content
    ========================================================================== */
    .content-padding {
        padding: $content-padding;
    }
    .container-narrow {
        .container-holder {
            max-width: 640px;
            margin: 0 auto;
        }
    }


/*  home-double
    ========================================================================== */
    .home-double {
        background-color: $beige;
    }

/*  slider-section
    ========================================================================== */
    &.home {
        .slider-section {
            padding: 6vh 0;
        }
    }
    .slider-section {
        padding: 6vh 0;
        .owl-carousel {
            min-height: 0;
            .item {
                @include media-breakpoint-up(sm) {
                    max-width: 760px;
                    min-height: 440px;
                    margin: 30px 0 0 auto;
                }
                padding: 0;
                .owl-caption {
                    position: absolute;
                    top: 0;
                    left: 0;
                    @include media-breakpoint-down(md) {
                        left: 30px;
					}
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 100%;
                    max-width: 440px;
                    min-height: 50%;
                    padding: 40px 30px;
                    @include media-breakpoint-down(xs) {
                        position: static;
                        margin: 70px 0;
                        padding: 20px;
                    }
                    background-color: $blue;
                    color: $white;
                    .owl-subtitle {
                        order: 0;
                    }
                    .owl-title {
                        order: 1;
                    }
                    .owl-description {
                        order: 2;
                    }
                    .owl-btn {
                        order: 3;
                    }
                }
            }
            .owl-nav {
                display: flex;
                left: auto;
                right: 15px;
                bottom: 15px;
                margin-bottom: 0;
                .owl-prev,
                .owl-next {
                    position: static;
                    width: auto;
                    height: auto;
                    margin-left: 5px;
                    font-size: 24px;
                    line-height: 1;
                    text-shadow: none;
                    &:hover {
                        color: $orange;
                    }
                }
            }
            .owl-dots {
                @extend .d-none;
            }
            &.turned {
                .item {
                    @include media-breakpoint-up(sm) {
                        margin: 30px auto 0 0;
                    }
                    .owl-caption {
                        left: auto;
                        right: 0;
                        @include media-breakpoint-down(md) {
                            right: 30px;
                        }
                    }
                }
                .owl-nav {
                    display: flex;
                    left: 10px;
                    right: auto;
                }
            }
        }
    }

/*  usps
    ========================================================================== */
    .usps {
        ul {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            @include media-breakpoint-up(md) {
                flex-direction: row;
            }
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                display: flex;
                align-items: center;
                margin: 5px 15px;
                @include media-breakpoint-up(md) {
                    margin: 10px 30px;
                }
                color: $blue;
                font-size: 16px;
                line-height: 20px;
                font-weight: 700;
                font-family: $font-family-tinos;
                a {
                    color: $blue;
                    &:hover {
                        color: $blue;
                        text-decoration: none;
                    }
                }
                i {
                    margin-right: 5px;
                    color: $orange;
                }
            }
        }
    }

/*  spacer
    ========================================================================== */
    .spacer {
        padding: 8vh 0 0 0;
        .owl-carousel {
            .owl-stage-outer {
                padding-bottom: 100px;
            }
            .item {
                padding: 0;
                .owl-caption {
                    position: absolute;
                    left: 100px;
                    bottom: -100px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 100%;
                    max-width: 440px;
                    min-height: 50%;
                    padding: 40px 30px;
                    @include media-breakpoint-down(xs) {
                        width: auto;
                        left: 15px;
                        right: 15px;
                        padding: 20px;
                    }
                    background-color: $blue;
                    color: $white;
                    .owl-subtitle {
                        order: 0;
                    }
                    .owl-title {
                        order: 1;
                    }
                    .owl-description {
                        order: 2;
                    }
                    .owl-btn {
                        order: 3;
                    }
                }
            }
            .owl-nav {
                display: flex;
                left: auto;
                right: 15px;
                bottom: 15px;
                margin-bottom: 100px;
                @include media-breakpoint-down(sm) {
                    top: 15px;
                    bottom: auto;
                }
                .owl-prev,
                .owl-next {
                    position: static;
                    width: auto;
                    height: auto;
                    margin-left: 5px;
                    font-size: 24px;
                    line-height: 1;
                    text-shadow: none;
                    &:hover {
                        color: $orange;
                    }
                }
            }
            .owl-dots {
                @extend .d-none;
            }
            &.turned {
                .item {
                    margin: 30px auto 0 0;
                    .owl-caption {
                        left: auto;
                        right: 0;
                    }
                }
                .owl-nav {
                    display: flex;
                    left: 10px;
                    right: auto;
                }
            }
        }
    }
/*  default
    ========================================================================== */
    .card {
        .card-body {
            .card-caption {
                .card-description {
                    &:after {
                        @extend .d-none;
                    }
                }
            }
            .card-buttons {
                .card-btn {
                    padding: 0;
                    border: none;
                    background: none;
                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
    }

    /*  round-blocks
    ========================================================================== */
    .round-blocks {
        &.grid {
            max-width: 580px;
            @include media-breakpoint-up(md) {
                margin: 0 auto;
            }
            .item {
                @include make-col(4);
                padding-left: 10px;
                padding-right: 10px;
            }
        }
        .item {
            .card {
                width: 160px;
                height: 160px !important;
                @include media-breakpoint-down(xs) {
                    width: 105px;
                    height: 105px !important;
                }
                margin: 0 auto;
                border-radius: 100%;
                background-color: $blue;
                border: none;
                box-shadow: none;
                color: $white;
                text-decoration: none;
                overflow: hidden;
                &:hover {
                    background-color: $orange;
                }
                .card-image {
                    @extend .d-none;
                }
                .card-body {
                    align-items: center;
                    justify-content: center;
                    padding-top: 0;
                    .card-caption {
                        flex: none;
                        padding: 15px;
                        .card-title {
                            margin-bottom: 30px;
                            color: $white;
                            font-size: 20px;
                            text-align: center;
                            @include media-breakpoint-down(xs) {
                                font-size: 16px;
                            }
                        }
                        .card-subtitle,
                        .card-description {
                            @extend .d-none;
                        }
                        ::after {
                            content: '\f0a9';
                            font-family: 'Font Awesome 5 Pro';
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 15px;
                            color: $white;
                            font-size: 24px;
                        }
                    }
                }
            }
        }
    }
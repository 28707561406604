.btn {
    padding: 0.375rem 1.25rem;
    border-radius: 0;
    background-color: $orange;
    border-color: $orange;
    box-shadow: none;
    color: $white;
    font-size: 16px;
    @include media-breakpoint-up(sm) {
        font-size: 18px;
    }
    font-weight: 300;
    &:hover {
        background-color: rgba($color: $orange, $alpha: 0.8);
        color: $white;
    }
    i { margin-right: 5px;}
}
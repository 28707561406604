.header {
    
    /* header-socket */
    .header-socket {
        background-color: $blue;
        padding: 0 15px;
        .column.navbar {
            padding: 0;
            .quick-contact {
                @include media-breakpoint-down(lg) {
                    display: none !important;
                }
                margin-bottom: 0;
                li {
                    display: flex;
                    margin-right: 30px;
                    a {
                        color: $white;
                        font-size: 12px;
                        i {
                            margin-right: 5px;
                            font-size: 18px;
                        }
                        &:hover {
                            color: $orange;
                            text-decoration: none;
                        }
                    }
                }
            } 
            .socials {
                .nav-item {
                    .nav-link {
                        color: $white;
                        font-size: 18px;
                        &:hover {
                            color: $orange;
                        }
                    }
                }
                @include media-breakpoint-up(xl) {
                    .nav-item:nth-child(-n+3) {
                        display: none;
                    }
                }
            }      
        }
    }

    /* header-main */
    .header-main {
        padding: 10px 15px;
        background-color: $white;

        .navbar {
            padding: 0;

            .logo {
                max-width: 130px;
                transition: 0.5s;
                a { display: block;
                    img { width: 100%;}
                }
            }    

            .navbar-toggler {
                border: none;
                outline: none;
                font-size: 24px;
                &:hover {
                    color: $primary;
                }
            }
            .menu {
                @include media-breakpoint-down(lg) {
                    order: 4;
                }
                .navbar-nav {
                    margin: 0 auto;
                    .nav-item {
                        margin: 0 10px;
                        font-size: 14px;
                        font-weight: 600;
                        .nav-link {
                            padding: 0;
                            color: $gray;  
                            text-decoration: none;
                        }
                        &.active a {
                            @include media-breakpoint-down(lg) {
                                color: $primary;
                            }
                        }  
                        .dropdown-menu {
                            background-color: $orange;
                            margin: 0;
                            border: none;
                            border-radius: 0;
                            .nav-item {
                                margin-left: 0;
                                text-transform: none;
                                .nav-link {
                                    margin-left: 0;
                                    padding: 5px 15px;
                                    color: $white;
                                    &:hover {
                                        color: $blue;
                                    }
                                }
                                &.active a {
                                    color: $blue;
                                } 
                            }
                        }
                        &.last {
                            .dropdown-menu {
                                //left: auto;
                                //right: 0;
                            }
                        }
                    }
                }
                &.collapsing {
                    margin-top: 15px;
                    .nav-item {
                        margin-left: 0;
                        .nav-link {
                            line-height: 30px;
                        }
                    }
                }
                &.show {
                    margin-top: 15px;
                    .nav-item {
                        margin-left: 0;
                        .nav-link {
                            line-height: 30px;
                        }
                    }
                }
            }

            .header-button {
                @include media-breakpoint-down(xs) {
                    display: none !important;
                }
                margin-bottom: 0;
                li {
                    margin-right: 0;
                    a {
                        @extend .btn;
                        padding: 0 15px !important;
                        font-size: 14px !important;
                        font-weight: 600 !important;
                        line-height: 40px !important;
                    }
                }
            }

        }
    }


/*  sticky
    ========================================================================== */
    @include media-breakpoint-up(xl) {
        &.sticky {
            .header-main {
                .logo {
                    max-width: 180px;
                }
            }
        }
    }
}
